import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import airtable from 'airtable';
import { loadCollectionPipelineFromAirtable } from '../services/airtable';
import { getProfile, getUserMetadata } from '../services/auth';
import { useInterval } from '../services/hooks';

import AssignedCollectionsGrid from './collections/assigned-collections-grid';
import Layout from './layout';
import MessageBlock from './message-block';

import '../styles/collections.scss';

const base = new airtable({ apiKey: process.env.GATSBY_AIRTABLE_KEY }).base( process.env.GATSBY_AIRTABLE_EDITORIAL_BASE );

const buildAssignedCollection = (rec) => {
  let collection = {
    name: rec.fields['Collection Name'] ? rec.fields['Collection Name'] : '',
    assignedDate: rec.fields['Assigned'] ? new Date(rec.fields['Assigned']) : null,
    dueDate: rec.fields['Due'] ? new Date(rec.fields['Due']) : null,
    projectedDate: rec.fields['Projected'] ? new Date(rec.fields['Projected']) : null,
    status: rec.fields['Status'] ? rec.fields['Status'] : '',
    googleDoc: rec.fields['Collection Draft'] ? rec.fields['Collection Draft'] : null,
    googleDrive: rec.fields['Collection Drive'] ? rec.fields['Collection Drive'] : null,
  };
  
  return collection;
};


const Assignments = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [assignedCollections, setAssignedCollections] = useState([]);
  const userMetadata = getUserMetadata();
  
  const clearMessage = () => {
    // Put the same path on the history stack without the message state so that reload doesn’t show the message again
    const _stateClone = Object.assign({}, props.location.state);
    _stateClone.message = null;
    
    navigate(props.location.pathname, { state: _stateClone, replace: true });
  }
  
  useInterval(async () => {
    let pipelineResult = await loadCollectionPipelineFromAirtable(base, userMetadata.internalId);
    pipelineResult = pipelineResult.map(rec => buildAssignedCollection(rec));
    const assignedResult = pipelineResult.filter(rec => rec.status !== 'Published');
    
    setAssignedCollections(assignedResult);
  }, 2000);
  
  useEffect(() => {
    const loadCollectionsFromAirtable = async () => {
      let pipelineResult = await loadCollectionPipelineFromAirtable(base, userMetadata.internalId);
      pipelineResult = pipelineResult.map(rec => buildAssignedCollection(rec));
      const assignedResult = pipelineResult.filter(rec => rec.status !== 'Published');
      
      setAssignedCollections(assignedResult);
      setLoaded(true);
    };
    
    loadCollectionsFromAirtable();
  }, []);
  
  return (
    <Layout title="Assigned Collections">
      {loaded && (
        <div className="page-section">
          {(assignedCollections.length > 0) ? (
            <AssignedCollectionsGrid
              collections={ assignedCollections }
            />
          ) : (
            <div className="empty-collections-list">
              No active collection assignments…
            </div>
          )}
          
        </div>
      )}
      
      {props.location.state && props.location.state.message && (
        <MessageBlock message={ props.location.state.message } clearMessage={ clearMessage } />
      )}
    </Layout>
  );
};

export default Assignments;