import React from 'react';

import '../../styles/dashboard.scss';

const sortedDates = (collections) => {
  const today = new Date();
  let dates = [];

  collections.forEach(collection => {
    if (collection.fields['Collection Name']) {
      if (collection.fields['Due']) {
        dates.push({
          name: collection.fields['Collection Name'][0],
          type: 'Due',
          styleTag: 'due-tag',
          overdueTag: (today > new Date(collection.fields['Due'])) ? 'overdue' : '',
          date: new Date(collection.fields['Due']),
        });
      }
      if (collection.fields['Projected']) {
        dates.push({
          name: collection.fields['Collection Name'][0],
          type: 'To Publish',
          styleTag: 'publish-tag',
          overdueTag: (today > new Date(collection.fields['Projected'])) ? 'overdue' : '',
          date: new Date(collection.fields['Projected']),
        });
      }
    }
  });

  dates = dates.sort((a, b) => {
    if (a.date > b.date) {
      return 1;
    }
    if (a.date < b.date) {
      return -1;
    }
    return 0;
  })

  return dates;
};

const DashboardEventsQueue = (props) => {
  const dates = sortedDates(props.collections);
  var formatter = new Intl.DateTimeFormat('en-us', {
    month: 'short',
    day: '2-digit',
    timeZone: 'UTC'
  });

  return (
    <div className="events">
      {dates.map(date => {
        const dateParts = formatter.formatToParts(date.date);
        const day = dateParts.filter(part => part.type === 'day')[0];
        const month = dateParts.filter(part => part.type === 'month')[0];
        const dateString = `${day.value} ${month.value}`;

        return (
          <div className={ `event-item ${date.styleTag} ${date.overdueTag}` }>
            <div className="date">
              { dateString }
            </div>
            <div className="name">
              { date.name }
            </div>
            <div className="label">
              { date.type }
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DashboardEventsQueue;
