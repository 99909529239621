import React from 'react';
import { Router } from '@reach/router';
import { login, isAuthenticated, getUserMetadata } from '../services/auth';

import Assignments from '../components/assignments';
import ClaimCollectionView from '../components/claim-collection/claim-collection-view';
import Collections from '../components/collections';
import Dashboard from '../components/dashboard';
import DeclineCollectionView from '../components/decline-collection/decline-collection-view';
import PitchCollectionView from '../components/pitch-collection';
import Prospects from '../components/prospects';


const App = () => {
  if (!isAuthenticated() || !getUserMetadata()) {
    login();
    return <p>Redirecting to login…</p>;
  }

  return (
    <Router basepath="/app">
      <Dashboard path="/dashboard" />
      <Collections path="/collections" />
      <Assignments path="/collections/assignments" />
      <Prospects path="/collections/prospects" />
      <ClaimCollectionView path="/collections/claim-collection" />
      <ClaimCollectionView path="/collections/claim-collection/:collectionId" />
      <DeclineCollectionView path="/collections/decline-collection" />
      <DeclineCollectionView path="/collections/decline-collection/:collectionId" />
      <PitchCollectionView path="/collections/pitch-collection" />
    </Router>
  );
};

export default App;
