import React from 'react';

const statusBubble = (collection) => {
  let colorClass = '';
  
  switch (collection.status) {
    case 'Incoming':
      colorClass = 'orange-bubble';
      break;
    case 'In Editing':
      colorClass = 'yellow-bubble';
      break;
    case 'In Production':
      colorClass = 'green-bubble';
      break;
    case 'Ready to Deploy':
      colorClass = 'purple-bubble';
      break;
  }
  
  return <span className={ `status-bubble ${colorClass}` }>{ collection.status }</span>;
}

const titleBar = (collection) => {
  let colorClass = '';
  
  switch (collection.status) {
    case 'Incoming':
      colorClass = 'orange-background';
      break;
    case 'In Editing':
      colorClass = 'yellow-background';
      break;
    case 'In Production':
      colorClass = 'green-background';
      break;
    case 'Ready to Deploy':
      colorClass = 'purple-background';
      break;
  }
  
  return <h3 className={ `title ${colorClass}` }>{ collection.name }</h3>;
}

const AssignedCollectionCell = ({ collection }) => (
  <div className="assigned-collection-cell">
    {titleBar(collection)}
    <div className="details">
      <div className="detail-row">
        <div className="detail-label">
          Status:
        </div>
        <div className="detail-content">
          {statusBubble(collection)}
        </div>
      </div>
      <div className="row-group">
        <div className="detail-row">
          <div className="detail-label">
            Due:
          </div>
          <div className="detail-content">
            { collection.dueDate ? collection.dueDate.toLocaleString([], { dateStyle: 'medium' }) : <i>No Due Date</i> }
          </div>
        </div>
        <div className="detail-row">
          <div className="detail-label">
            To Publish:
          </div>
          <div className="detail-content">
            { collection.projectedDate ? collection.projectedDate.toLocaleString([], { dateStyle: 'medium' }) : <i>No Publishing Date</i> }
          </div>
        </div>
        <div className="detail-row">
          <div className="detail-label">
            Assigned:
          </div>
          <div className="detail-content">
            { collection.assignedDate ? collection.assignedDate.toLocaleString([], { dateStyle: 'medium' }) : <i>No Assigned Date</i> }
          </div>
        </div>
      </div>
      <div className="row-group">
        <div className="detail-row">
          <div className="detail-label">
            Google Doc:
          </div>
          <div className="detail-content">
            { collection.googleDoc && (
              <a href={ collection.googleDoc } target="_blank">Open in New Tab</a>
            )}
          </div>
        </div>
        <div className="detail-row">
          <div className="detail-label">
            Google Drive:
          </div>
          <div className="detail-content">
            { collection.googleDrive && (
              <a href={ collection.googleDrive } target="_blank">Open in New Tab</a>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AssignedCollectionCell;