import React from 'react';
import { Link } from 'gatsby';

import Layout from './layout';
import LinkGrid from './link-grid';

const Collections = () => (
  <Layout title="Collections">
    <LinkGrid>
      <Link to="/app/collections/assignments">Assignments</Link>
      <Link to="/app/collections/prospects">Prospective Collections</Link>
      <Link to="/app/collections/pitch-collection">Pitch Collection</Link>
    </LinkGrid>
  </Layout>
);

export default Collections;