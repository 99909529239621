import React from 'react';

import '../styles/link-grid.scss';

const LinkGrid = (props) => (
  <div className="link-grid">
    { props.children }
  </div>
);

export default LinkGrid;