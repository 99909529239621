import React, { useEffect, useState } from 'react';

import '../styles/message-block.scss';

const MessageBlock = ({ message, clearMessage }) => {
  const [visible, setVisible] = useState(true);
  
  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
      
      // Give CSS animation a chance to finish before clearing message
      setTimeout(() => clearMessage(), 500);
    }, 5000);
  }, []);
  
  return (
    <div className={`message-block__wrapper ${visible ? 'message-block__show' : 'message-block__hide'}`}>
      <div className="message-block__content">
        { message }
      </div>
    </div>
  );
};

export default MessageBlock;