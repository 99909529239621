import React from 'react';
import { Link } from 'gatsby';
import marked from 'marked';

import '../../styles/collection-assignments.scss';

const PendingAssignmentRow = ({ collection }) => (
  <div className="collection-assignments-row">
    <div className="header">
      <div className="name">
        { collection.fields['Name'] }
      </div>
      <div className="button">
        <Link
          to={ `/app/collections/decline-collection/${collection.id}` }
        >
          Decline
        </Link>
      </div>
      <div className="button">
        <Link
          to={ `/app/collections/claim-collection/${collection.id}` }
        >
          Claim
        </Link>
      </div>
    </div>
    <div className="details">
      {collection.fields['Source'] && (
        <div className="detail-row">
          <div className="detail-label">
            Source:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Source']).replace('<p>', '').replace('</p>', '') }}
          />
        </div>
      )}
      {collection.fields['Source 2'] && (
        <div className="detail-row">
          <div className="detail-label">
            Source 2:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Source 2']).replace('<p>', '').replace('</p>', '') }}
          />
        </div>
      )}
      {collection.fields['Additional Links'] && (
        <div className="detail-row">
          <div className="detail-label">
            Additional Links:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Additional Links']) }}
          />
        </div>
      )}
      {collection.fields['Notes'] && (
        <div className="detail-row">
          <div className="detail-label">
            Notes:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Notes']) }}
          />
        </div>
      )}
      {collection.fields['Attribution'] && (
        <div className="detail-row">
          <div className="detail-label">
            Attribution:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Attribution']).replace('<p>', '').replace('</p>', '') }}
          />
        </div>
      )}
      {collection.fields['Contributor Names'] && (
        <div className="detail-row">
          <div className="detail-label">
            Potential Contributor(s):
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Contributor Names'].toString().replace(/,/g, ', ')).replace('<p>', '').replace('</p>', '') }}
          />
        </div>
      )}
    </div>
  </div>
);

export default PendingAssignmentRow;
