import React, { useState } from 'react';
import { navigate } from '@reach/router';
import DatePicker from 'react-datepicker';

import { getUserMetadata } from '../../services/auth';

import '../../styles/form.scss';
import 'react-datepicker/dist/react-datepicker.css';

const ClaimCollectionForm = ({ collection, editorId }) => {
  const userMetadata = getUserMetadata();
  const [startDate, setStartDate] = useState(new Date(Date.now()+ 12096e5)); // Init w/ two weeks in future
  const [name, setName] = useState((collection.fields && collection.fields['Name']) ? collection.fields['Name'] : '');
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  return (
    <form className="standard-form">
      <div className="form-row">
        <div className="form-label">
          Name:
        </div>
        <div className="form-content">
          <input
            type="text"
            name="name"
            value={ name }
            onChange={ e => setName(e.target.value) }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-label">
          Contributing Editor:
        </div>
        <div className="form-content">
          { userMetadata.displayLongName }
        </div>
      </div>
      <div className="form-row">
        <div className="form-label">
          Submission Date:
        </div>
        <div className="form-content">
          <DatePicker
            selected={ startDate }
            name="submissionDate"
            highlightDates={ [Date.now()] }
            onChange={ date => setStartDate(date) }
          />
        </div>
      </div>
      <div class="form-row">
        <div className="form-label">
        
        </div>
        <div className="form-content">
          {formSubmitted ? (
            <>
              Submitting…
            </>
          ) : (
            <button
              className="submit-button"
              onClick={(e) => {
                e.preventDefault();
                
                // Disable form while claim is processing
                setFormSubmitted(true);

                const payload = {
                  editorId: editorId,
                  editorInternalId: userMetadata.internalId,
                  name: name,
                  startDate: startDate.toISOString(),
                  assignedDate: new Date().toISOString(),
                  collectionId: collection.id,
                };
                
                fetch('https://hooks.zapier.com/hooks/catch/10896379/bt8gaoz/', {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                  },
                  body: JSON.stringify(payload)
                }).then((resp) => {
                  if (resp.status === 200) {
                    navigate('/app/collections/prospects', { state: { message: `You claimed the collection ‘${name}’` } });
                  } else {
                    console.log(resp);
                  }
                });
                
                
              }}
            >
              Claim Collection
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default ClaimCollectionForm;