import React, { useState } from 'react';
import { navigate } from '@reach/router';

import { getUserMetadata } from '../../services/auth';

import '../../styles/form.scss';

const DeclineCollectionForm = ({ collection, editorId }) => {
  const userMetadata = getUserMetadata();

  const [note, setNote] = useState('');

  return (
    <form className="standard-form">
      <div className="form-row">
        <div className="form-label">
          Contributing Editor:
        </div>
        <div className="form.content">
          { userMetadata.displayLongName }
        </div>
      </div>
      <div className="form-row">
        <div className="form-label">
          Reason to Decline:
        </div>
        <div className="form-content">
          <textarea
            name="note"
            value={ note }
            onChange={ e => setNote(e.target.value) }
          />
        </div>
      </div>
      <div class="form-row">
        <div className="form-label">

        </div>
        <div className="form-content">
          <button
            className="submit-button"
            onClick={e => {
              e.preventDefault();

              const payload = {
                editorId: editorId,
                editorInternalId: userMetadata.internalId,
                editorName: userMetadata.displayLongName,
                note: note,
                collectionId: collection.id
              };

              fetch('https://hooks.zapier.com/hooks/catch/10896379/bhk2rtj/', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                },
                body: JSON.stringify(payload)
              }).then((resp) => {
                if (resp.status === 200) {
                  navigate('/app/collections/prospects', { state: { message: `You declined the collection ‘${collection.fields['Name']}’`}});
                } else {
                  console.log(resp);
                }
              });
            }}
          >
            Decline Collection
          </button>
        </div>
      </div>
    </form>
  );
};

export default DeclineCollectionForm;
