import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import airtable from 'airtable';
import {
  loadCollectionPipelineFromAirtable,
  loadCollectionPitchesFromAirtable 
} from '../services/airtable';
import { getProfile, getUserMetadata } from '../services/auth';
import { useInterval } from '../services/hooks';

import DashboardEventsQueue from './dashboard/events-queue';
import Layout from './layout';

import '../styles/dashboard.scss';

const base = new airtable({ apiKey: process.env.GATSBY_AIRTABLE_KEY }).base( process.env.GATSBY_AIRTABLE_EDITORIAL_BASE );

const getActiveAssiginments = async (userMetadata) => {
  const pipelineResult = await loadCollectionPipelineFromAirtable(base, userMetadata.internalId);
  const activeAssignments = pipelineResult.filter(collection => collection.fields['Status'] !== 'Published');
  
  return activeAssignments;
};

const getPitches = async (userMetadata) => {
  const result = await loadCollectionPitchesFromAirtable(base, userMetadata.displayLongName);
  let pendingResult = [];
  let pitchedResult = [];
  
  result.forEach(rec => {
    if (rec.fields['Assignment'] === 'Assignment Pending') {
      pendingResult.push(rec);
    } else if (rec.fields['Assignment'] === 'Pitched') {
      pitchedResult.push(rec);
    }
  });
  
  return {
    pending: pendingResult,
    pitched: pitchedResult,
  };
};

const Dashboard = () => {
  const profile = getProfile();
  const userMetadata = getUserMetadata();
  const [loaded, setLoaded] = useState(false);
  const [assignedCollections, setAssignedCollections] = useState([]);
  const [pendingCollections, setPendingCollections] = useState([]);
  const [pitchedCollections, setPitchedCollections] = useState([]);
  
  useInterval(async () => {
    const activeAssignments = await getActiveAssiginments(userMetadata);
    const { pending, pitched } = await getPitches(userMetadata);
    
    setAssignedCollections(activeAssignments);
    setPendingCollections(pending);
    setPitchedCollections(pitched);
  }, 2000);
  
  useEffect(() => {
    const loadCollectionsFromAirtable = async () => {
      const activeAssignments = await getActiveAssiginments(userMetadata);
      const { pending, pitched } = await getPitches(userMetadata);
        
      setAssignedCollections(activeAssignments);
      setPendingCollections(pending);
      setPitchedCollections(pitched);
      setLoaded(true);
    };
    
    loadCollectionsFromAirtable();
  },[]);
  
  return (
    <Layout title="Dashboard">
      <div className="dashboard-grid">
        <div className="dashboard-events-queue">
          {loaded && (
            <DashboardEventsQueue collections={ assignedCollections } />
          )}
        </div>
        <div className="dashboard-stats-list">
          {loaded && (
            <>
              <Link
                className="stat-item"
                to="/app/collections/assignments"
              >
                <span className="number">{assignedCollections.length}</span>
                <span className="label">Assigned Collections</span>
              </Link>
              <Link
                className="stat-item"
                to="/app/collections/prospects"
              >
                <span className="number">{pendingCollections.length}</span>
                <span className="label">Pending Collections</span>
              </Link>
              <Link
                className="stat-item"
                to="/app/collections/prospects"
              >
                <span className="number">{pitchedCollections.length}</span>
                <span className="label">Pitched Collections</span>
              </Link>
            </>
          )}
        </div>
        <div className="dashboard-details">
          <div className="bio-details">
            <h3>Your Details</h3>
            <div className="detail-row">
              <div className="detail-label">
                Name:
              </div>
              <div className="detail-content">
                {userMetadata.displayLongName}
              </div>
            </div>
            <div className="detail-row">
              <div className="detail-label">
                Email:
              </div>
              <div className="detail-content">
                {profile.email}
              </div>
            </div>
            <div className="detail-row">
              <div className="detail-label">
                Calendar:
              </div>
              <div className="detail-content">
                <a
                  href={ userMetadata.calendarURL }
                >
                  Subscribe
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;