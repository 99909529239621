export const airtableElementsFromIds = async (base, table, recIds) => {
  if (!recIds) { return []; }
  
  const promises = recIds.map(recId => {
    return new Promise((resolve, reject) => {
      base(table).find(recId, function(err, record) {
        if (err) { console.error(err); return; }
        resolve(record);
      });
    });
  });
  
  return Promise.all(promises);
};

export const loadCollectionPipelineFromAirtable = async (base, internalId) => {
  const pipelinePromise = new Promise((resolve, reject) => {
    let collections = [];
    
    base('Pipeline').select({
      filterByFormula: `{Internal Contributor ID} = "${internalId}"`
    }).eachPage(function page(records, fetchNextPage) {
        collections = collections.concat(records);
        
        fetchNextPage();
      }, function done(err) {
        if (err) { console.error(err); return; }
        resolve(collections);
      });
  });
  
  const result = await pipelinePromise;
  
  return result;
};

export const loadCollectionPitchesFromAirtable = async (base, displayLongName) => {
  const collectionsPromise = new Promise((resolve, reject) => {
    let collections = [];
    
    base('Collections – Pitches').select({
      filterByFormula: `SEARCH("${displayLongName}", {Potential Contributor(s)})`
    }).eachPage(function page(records, fetchNextPage) {
      collections = collections.concat(records);
      
      fetchNextPage();
    }, function done(err) {
      if (err) { console.error(err); return; }
      resolve(collections);
    });
  });
  
  const result = await collectionsPromise;
  
  return result;
};