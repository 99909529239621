import React from 'react';
import marked from 'marked';

import '../../styles/collection-assignments.scss';

const PitchedCollectionRow = ({ collection }) => (
  <div className="collection-assignments-row">
    <div className="header">
      <div className="name">
        { collection.fields['Name'] }
      </div>
    </div>
    <div className="details">
      {collection.fields['Source'] && (
        <div className="detail-row">
          <div className="detail-label">
            Source:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Source']).replace('<p>', '').replace('</p>', '') }}
          />
        </div>
      )}
      {collection.fields['Source 2'] && (
        <div className="detail-row">
          <div className="detail-label">
            Source 2:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Source 2']).replace('<p>', '').replace('</p>', '') }}
          />
        </div>
      )}
      {collection.fields['Additional Links'] && (
        <div className="detail-row">
          <div className="detail-label">
            Additional Links:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Additional Links']) }}
          />
        </div>
      )}
      {collection.fields['Attribution'] && (
        <div className="detail-row">
          <div className="detail-label">
            Attribution:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(collection.fields['Attribution']).replace('<p>','').replace('</p>','') }}
          />
        </div>
      )}
      {collection.fields['Submitted'] && (
        <div className="detail-row">
          <div className="detail-label">
            Submitted Date:
          </div>
          <div
            className="detail-content"
            dangerouslySetInnerHTML={{ __html: marked(new Date(collection.fields['Submitted']).toLocaleString([], { dateStyle: 'medium' })).replace('<p>','').replace('</p>','') }}
          />
        </div>
      )}
    </div>
  </div>
);

export default PitchedCollectionRow;