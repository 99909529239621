import React from 'react';
import marked from 'marked';

import '../../styles/form.scss';

const CollectionPitch = ({ collection }) => (
  <div className="standard-form highlighted-form">
    {collection.fields['Name'] && (
      <div className="form-row">
        <div className="form-label">
          Name:
        </div>
        <div
          className="form-content"
          dangerouslySetInnerHTML={{ __html: collection.fields['Name']}}
        />
      </div>
    )}
    {collection.fields['Source'] && (
      <div className="form-row">
        <div className="form-label">
          Source:
        </div>
        <div
          className="form-content"
          dangerouslySetInnerHTML={{ __html: marked(collection.fields['Source']).replace('<p>', '').replace('</p>', '') }}
        />
      </div>
    )}
    {collection.fields['Source 2'] && (
      <div className="form-row">
        <div className="form-label">
          Source 2
        </div>
        <div
          className="form-content"
          dangerouslySetInnerHTML={{ __html: marked(collection.fields['Source 2']).replace('<p>', '').replace('</p>', '') }}
        />
      </div>
    )}
    {collection.fields['Additional Links'] && (
      <div className="form-row">
        <div className="form-label">
          Additional Links:
        </div>
        <div
          className="form-content"
          dangerouslySetInnerHTML={{ __html: marked(collection.fields['Additional Links']) }}
        />
      </div>
    )}
    {collection.fields['Notes'] && (
      <div className="form-row">
        <div className="form-label">
          Notes:
        </div>
        <div
          className="form-content"
          dangerouslySetInnerHTML={{ __html: marked(collection.fields['Notes']) }}
        />
      </div>
    )}
    {collection.fields['Attribution'] && (
      <div className="form-row">
        <div className="form-label">
          Attribution:
        </div>
        <div
          className="form-content"
          dangerouslySetInnerHTML={{ __html: marked(collection.fields['Attribution']).replace('<p>', '').replace('</p>', '') }}
        />
      </div>
    )}
  </div>
);

export default CollectionPitch;