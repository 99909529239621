import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import airtable from 'airtable';
import { loadCollectionPitchesFromAirtable } from '../services/airtable';
import { getProfile, getUserMetadata } from '../services/auth';
import { useInterval } from '../services/hooks';

import Layout from './layout';
import MessageBlock from './message-block';
import PendingAssignmentRow from './collections/pending-assignment-row';
import PitchedCollectionRow from './collections/pitched-collection-row';

import '../styles/collections.scss';

const base = new airtable({ apiKey: process.env.GATSBY_AIRTABLE_KEY }).base( process.env.GATSBY_AIRTABLE_EDITORIAL_BASE );

const getPitches = async () => {
  const userMetadata = getUserMetadata();
  
  const result = await loadCollectionPitchesFromAirtable(base, userMetadata.displayLongName);
  let pendingResult = [];
  let pitchedResult = [];
  
  result.forEach(rec => {
    if (rec.fields['Assignment'] === 'Assignment Pending') {
      pendingResult.push(rec);
    } else if (rec.fields['Assignment'] === 'Pitched') {
      pitchedResult.push(rec);
    }
  });
  
  return {
    pending: pendingResult,
    pitched: pitchedResult,
  };
};

const Prospects = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [pendingCollections, setPendingCollections] = useState([]);
  const [pitchedCollections, setPitchedCollections] = useState([]);
  
  const clearMessage = () => {
    // Put the same path on the history stack without the message state so that reload doesn’t show the message again
    const _stateClone = Object.assign({}, props.location.state);
    _stateClone.message = null;
    
    navigate(props.location.pathname, { state: _stateClone, replace: true });
  }
  
  useInterval(async () => {
    const result = await getPitches();
    
    setPendingCollections(result.pending);
    setPitchedCollections(result.pitched);
  }, 2000);
  
  useEffect(() => {
    const loadCollectionsFromAirtable = async () => {
      const result = await getPitches();
      
      setPendingCollections(result.pending);
      setPitchedCollections(result.pitched);
      setLoaded(true);
    };
    
    loadCollectionsFromAirtable();
  }, []);
  
  return (
    <Layout title="Prospective Collections">
      {loaded && (
        <>
          <div className="page-section">
            <h3 className="collections-header">Collections Pending Assignment</h3>
            {(pendingCollections.length > 0) ? (
              pendingCollections.map((collection, idx) => (
                <PendingAssignmentRow
                  key={`pending-collection-${idx}`}
                  collection={ collection }
                />
              ))
            ) : (
              <div className="empty-collections-list">
                No collections pending assignment…
              </div>
            )}
          </div>
          <div className="page-section">
            <h3 className="collections-header">Pitches Awaiting Approval</h3>
            {(pitchedCollections.length > 0) ? (
              pitchedCollections.map((collection, idx) => (
                <PitchedCollectionRow
                  key={`pitched-collection-${idx}`}
                  collection={ collection }
                />
              ))
            ) : (
              <div className="empty-collections-list">
                No pitches awaiting approval…
              </div>
            )}
          </div>
        </>
      )}
    </Layout>
  )
};

export default Prospects;