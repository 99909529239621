import React, { useEffect, useState } from 'react';
import airtable from 'airtable';

import { airtableElementsFromIds } from '../../services/airtable';
import { getUserMetadata, getProfile } from '../../services/auth';

import ClaimCollectionForm from './claim-collection-form';
import CollectionPitch from './collection-pitch';
import Layout from '../layout';

const base = new airtable({ apiKey: process.env.GATSBY_AIRTABLE_KEY }).base(process.env.GATSBY_AIRTABLE_EDITORIAL_BASE);

const ClaimCollectionView = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [collection, setCollection] = useState({});
  const [editorId, setEditorId] = useState();

  const userMetadata = getUserMetadata();

  useEffect(() => {
    const loadFromAirtable = async () => {
      if (props.collectionId) {
        const collectionResults = await airtableElementsFromIds(base, 'Collections – Pitches', [props.collectionId]);

        if (collectionResults) {
          setCollection(collectionResults[0]);
          setLoaded(true);
        }
      }
    };

    const loadEditorId = async () => {
      const loadPromise = new Promise((resolve, reject) => {
        let editors = [];

        base('People').select({
          view: "Editors and Contributing Editors"
        }).eachPage(function page(records, fetchNextPage) {
          editors = editors.concat(records);

          fetchNextPage();
        }, function done(err) {
          if (err) { console.error(err); return; }
          resolve(editors);
        });
      });

      const result = await loadPromise;

      result.forEach(rec => {
        if (rec.fields['Internal ID'] && rec.fields['Internal ID'] === userMetadata.internalId) {
          setEditorId(rec.id);
        }
      })
    };

    loadFromAirtable();
    loadEditorId();
  }, []);

  return (
    <Layout title="Claim Collection">
      <div>
        {loaded ? (
          <>
            <CollectionPitch collection={ collection } />
            <ClaimCollectionForm
              collection={ collection }
              editorId={ editorId }
            />
          </>
        ) : (
          <div>Loading…</div>
        )}
      </div>
    </Layout>
  )
};

export default ClaimCollectionView;
