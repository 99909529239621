import React, { useState } from 'react';
import { navigate } from '@reach/router';

import { getUserMetadata } from '../services/auth';
import Layout from './layout';

const PitchCollectionView = (props) => {
  const userMetadata = getUserMetadata();
  const [name, setName] = useState('');
  const [source, setSource] = useState('');
  const [source2, setSource2] = useState('');
  const [additionalLinks, setAdditionalLinks] = useState('');
  const [attribution, setAttribution] = useState('');
  const [notes, setNotes] = useState('');
  const [type, setType] = useState('');
  
  return (
    <Layout title="Pitch Collection">
      <form className="standard-form">
        <div className="form-row">
          <div className="form-label">
            Name:
          </div>
          <div className="form-content">
            <input
              type="text"
              name="name"
              value={ name }
              onChange={ e => setName(e.target.value) }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-label">
            Source:
          </div>
          <div className="form-content">
            <input
              type="text"
              name="source"
              value={ source }
              onChange={ e => setSource(e.target.value) }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-label">
            Source 2:
          </div>
          <div className="form-content">
            <input
              type="text"
              name="source2"
              value={ source2 }
              onChange={ e => setSource2(e.target.value) }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-label">
            Additional Links:
          </div>
          <div className="form-content">
            <textarea
              name="additionalLinks"
              value={ additionalLinks }
              onChange={ e => setAdditionalLinks(e.target.value) }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-label">
            Attribution:
          </div>
          <div className="form-content">
            <input
              type="text"
              name="attribution"
              value={ attribution }
              onChange={ e => setAttribution(e.target.value) }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-label">
            Notes:
          </div>
          <div className="form-content">
            <textarea
              name="notes"
              value={ notes }
              onChange= { e => setNotes(e.target.value) }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-label">
            Type:
          </div>
          <div className="form-content">
            <select
              name="type"
              value={ type }
              onChange={ e => setType(e.target.value) }
            >
              <option value="">Select a type…</option>
              <option value="Images">Images</option>
              <option value="Books">Books</option>
              <option value="Film">Film</option>
              <option value="Audio">Audio</option>
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-label">
          
          </div>
          <div className="form-content">
            <button
              className="submit-button"
              onClick={(e) => {
                e.preventDefault();
                
                const payload = {
                  name: name,
                  source: source,
                  source2: source2,
                  additionalLinks: additionalLinks,
                  attribution: attribution,
                  notes: notes,
                  type: type,
                  editorInternalId: userMetadata.internalId,
                };
                
                fetch('https://hooks.zapier.com/hooks/catch/10896379/bh4hzbh/',
                  {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                    },
                    body: JSON.stringify(payload)
                  }).then((resp) => {
                    if (resp.status === 200) {
                      navigate('/app/collections/prospects', { state: { message: `You pitched the collection ‘${name}’` } });
                    } else {
                      console.log(resp);
                    }
                  });
              }}
            >
              Pitch Collection
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default PitchCollectionView;