import React from 'react';

import AssignedCollectionCell from './assigned-collection-cell';

import '../../styles/assigned-collections-grid.scss';

const sortedCollections = (collections) => {
  return collections.sort((a, b) => {
    if (a.dueDate > b.dueDate) {
      return 1;
    }
    if (a.dueDate < b.dueDate) {
      return -1;
    }
    return 0;
  });
};

const AssignedCollectionsGrid = ({ collections }) => (
  <div className="assigned-collections-grid">
    {sortedCollections(collections).map((collection, idx) => (
      <AssignedCollectionCell
        key={ `assigned-collection-${idx}` }
        collection={ collection }
      />
    ))}
  </div>
)

export default AssignedCollectionsGrid;